<template>
  <div id="InvoicingPlanning">
    <v-container fluid app>
      <ZnapHotGrid :options="options" :idPlanningFunction="idPlanningFunction">
      </ZnapHotGrid>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import ZnapHotGrid from "@/components/znap/HotGridInvoicing.vue";

export default {
  name: "InvoicingPlanning",

  components: {
    ZnapHotGrid,
  },

  props: {
    idPlanningFunction: { type: Number, required: false },
  },

  data() {
    return {
      options: [
        {
          column: "id_event",
          is: "ComboBox",
          items: [],
          multiple: false,
          label: "Evento *",
          required: true,
          filterable: false,
          endpoint: [Vue.prototype.$ipEvent, "event"],
          conditions: [
            {
              AndOr: "AND",
              column: "id_module",
              operator: "=",
              value: 5, // fixo
            },
            {
              AndOr: "AND",
              column: "id_status",
              operator: "NOT LIKE",
              value: 2, // fixo
            },
            {
              AndOr: "OR",
              column: "id_planning_function",
              operator: "=",
              value: 14, // fixo
            },
          ],
        },
        {
          column: "id_customer_group",
          is: "ComboBox",
          items: [],
          multiple: false,
          label: "Conta cliente",
          required: true,
          filterable: true,
          endpoint: [Vue.prototype.$ipCustomer, "customer-group"],
          conditions: [
            // {
            //     AndOr: "AND",
            //     column: "id_sales_force",
            //     operator: "IN",
            //     value: null, // pegar dinâmico
            // },
            // {
            //     AndOr: "AND",
            //     column: "id_sales_channel",
            //     operator: "IN",
            //     value: null, // pegar dinâmico
            // }
          ],
        },
        {
          column: "id_cost_center",
          is: "ComboBox",
          items: [],
          multiple: false,
          label: "Novo CDC",
          required: true,
          filterable: true,
          endpoint: [Vue.prototype.$ipOrganization, "cost-center"],
          conditions: [
            {
              AndOr: "AND",
              column: "id_cost_center_type",
              operator: "=",
              value: 1, // fixo
            },
          ],
        },
        {
          column: "id_sales_channel",
          is: "ComboBox",
          items: [],
          multiple: false,
          label: "Canal de vendas",
          required: true,
          filterable: true,
          endpoint: [Vue.prototype.$ipSales, "sales-channel"],
          conditions: [
            {
              AndOr: "AND",
              column: "id_sales_channel_type",
              operator: "=",
              value: null, // pegar do evento
            },
          ],
        },
        {
          column: "id_sales_force",
          is: "ComboBox",
          items: [],
          multiple: false,
          label: "Gerência executiva",
          required: true,
          filterable: true,
          endpoint: [Vue.prototype.$ipSales, "sales-force"],
          conditions: [
            {
              AndOr: "AND",
              column: "id_sales_force_type",
              operator: "=",
              value: 6, // gerente executivo
            },
          ],
        },
        {
          column: "id_sales_force_bp",
          is: "ComboBox",
          items: [],
          multiple: false,
          label: "BP",
          required: true,
          filterable: true,
          endpoint: [Vue.prototype.$ipSales, "sales-force"],
          conditions: [
            {
              AndOr: "AND",
              column: "id_sales_force_type",
              operator: "=",
              value: 7, // bp
            },
          ],
        },
        {
          column: "id_product",
          is: "ComboBox",
          items: [],
          multiple: false,
          label: "Projeto",
          required: true,
          filterable: true,
          endpoint: [Vue.prototype.$ipProduct, "product-sales-planning"],
          conditions: [
            {
              AndOr: "AND",
              column: "fiscal_year",
              operator: "=",
              value: null, // pegar do evento
            },
            {
              AndOr: "AND",
              column: "id_event_type",
              operator: "=",
              value: null, // pegar do evento
            },
            {
              AndOr: "AND",
              column: "planning_by_product",
              operator: "=",
              value: 1, // fixo
            },
          ],
        },
        {
          column: "id_payment_condition",
          is: "ComboBox",
          items: [],
          multiple: false,
          label: "Condição de pagamento",
          required: true,
          filterable: true,
          endpoint: [Vue.prototype.$ipPayment, "payment-condition"],
          conditions: [],
        },
        {
          column: "id_status_doc_type",
          is: "ComboBox",
          items: [],
          multiple: false,
          label: "NF/ Recibo",
          required: true,
          filterable: true,
          endpoint: [Vue.prototype.$ipUnit, "status"],
          conditions: [
            {
              AndOr: "AND",
              column: "id_status_type",
              operator: "=",
              value: 9, // fixo
            },
          ],
        },
        {
          column: "id_status_financial",
          is: "ComboBox",
          items: [],
          multiple: false,
          label: "Status do aporte",
          required: true,
          filterable: true,
          endpoint: [Vue.prototype.$ipUnit, "status"],
          conditions: [
            {
              AndOr: "AND",
              column: "id_status_type",
              operator: "=",
              value: 10, // fixo
            },
          ],
        },
        {
          column: "id_planning_group",
          is: "ComboBox",
          items: [],
          multiple: false,
          label: "Grupo de planejamento",
          required: true,
          filterable: true,
          endpoint: [Vue.prototype.$ipApprovalFlow, "planning-group"],
          conditions: [],
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>